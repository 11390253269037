<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1 v-if="editMode">{{ $t("drone-detail-title") }}</h1>
      <h1 v-else>{{ $t("drone-register-title") }}</h1>
<!--      <div>
        &lt;!&ndash;        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >&ndash;&gt;
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="contents register drone">
      <div class="mainBox">
        <div v-if="editMode" class="flexL">
          <p class="bold">{{ $t("device-data-id") }}</p>
          <p>{{ deviceId }}</p>
        </div>
        <div v-if="editMode" class="flexL">
          <p class="bold">
            {{ $t("device-data-serial-num") }}
            <span>*</span>
          </p>
          <p>
            {{ serial }}
          </p>
        </div>
        <div v-else class="flexL">
          <p class="bold">
            {{ $t("device-data-serial-num") }}
            <span>*</span>
          </p>
          <input type="text"
                 :value="serial"
                 @input="bindNumberAndEn"/>
          <button class="small margin6" @click="handleDevice">
            {{ $t("btn-duplicate") }}
          </button>
          <p class="error margin6">{{ message }}</p>
        </div>
        <div class="flexL">
          <p class="bold">
            {{ $t("device-data-manufacturer") }}
            <span>*</span>
          </p>
          <label
              :for="item.name"
              v-for="(item, i) in manufacturerList"
              :key="i"
          >
            <input
                type="radio"
                name="manufacturer"
                :id="item.name"
                :value="item.deviceManufacturerId"
                v-model="selectManufacturerId"
                @change="onSelectManufacture(item.deviceManufacturerId)"
            />
            <span>{{ item.name }}</span>
          </label>
        </div>
        <div class="flexL">
          <p class="bold">
            {{ $t("device-data-model-name") }}
            <span>*</span>
          </p>
          <select v-model="selectModelId">
            <option disabled :value="null" @change="onSelectModel(null)">{{ $t("select-box-default-choose") }}</option>
            <option
                :selected="selectModelId"
                v-for="(item, i) in modelList"
                :key="i"
                :value="item.deviceModelId.deviceModelId"
                @change="onSelectModel(item.deviceModelId.deviceModelId)"
            >
              {{ item ? item.name : modelObj.name }}
            </option>
          </select>
        </div>
        <div class="flexL">
          <p class="bold">{{ $t("device-data-approval-num") }}</p>
          <input type="text" v-model="approval" />
        </div>
        <div class="flexL">
          <p class="bold">
            {{ $t("device-data-device-name") }}
            <span>*</span>
          </p>
          <input type="text" v-model="deviceName" />
        </div>
        <div class="flexL">
          <p class="bold">
            {{ $t("device-data-manager-dept-name") }}
            <span>*</span>
          </p>
          <!-- <input type="text" class="small" v-model="ownerTitle" /> -->
          <select v-model="department" @change="departmentCheck">
            <option disabled :value="null">{{ $t("select-box-default-choose") }}</option>
            <option
                :selected="department"
                v-for="(data, i) in departmentList"
                :key="i"
                :value="data.departmentId"
            >
              {{ data.name }}
            </option>
          </select>
        </div>
        <div class="flexB" style="max-width: 912px;">
          <div class="flexL">
            <p class="bold">{{ $t("device-data-manager-name") }} <span style="color: #f42e15;">*</span></p>
            <input type="text" class="small" v-model="ownerName" />
          </div>
          <div class="flexL">
            <p class="bold">{{ $t("device-data-manager-phone-num") }} <span>*</span></p>
            <input
                type="text"
                class="small"
                :value="ownerPhone"
                @input="bindNumber"
            />
          </div>
        </div>
        <div class="flexL">
          <p class="bold">
            {{ $t("device-data-image") }}
            <br />(1280*720/jpg)
          </p>
          <div>
            <label>
              <input
                  type="radio"
                  name="image"
                  checked
                  v-model="defaultImage"
                  :value="true"
                  @change="handleImg"
              />
              <span>{{ $t("device-data-default-img") }}</span>
            </label>
            <label>
              <input
                  type="radio"
                  name="image"
                  v-model="defaultImage"
                  :value="false"
                  @change="handleImg"
              />
              <span>{{ $t("device-data-upload-img") }}</span>
            </label>
            <div class="flexB" v-if="!defaultImage">
              <div class="filebox">
                <input
                    class="upload-name"
                    value="Select file.."
                    disabled="disabled"
                />
                <label for="ex_file" style="font-weight: bold; font-size: 1.2rem;">{{ $t("btn-upload") }}</label>
                <input
                    type="file"
                    ref="images"
                    id="ex_file"
                    @change="sendFile()"
                />
              </div>
              <!--              <button class="small" @click="handleUpload">
                              {{ $t("btn-upload") }}
                            </button>-->
            </div>
            <div class="droneImg" v-if="defaultImage == true">
              <img :src="getDeviceImgDefault()" alt="이미지" id="img" />
            </div>
            <div class="droneImg" v-else-if="defaultImage == false">
              <template v-if="deviceInfo != null && deviceInfo.defaultIsImage == false && file == ''">
                <img :src="deviceImageUrl" alt="이미지" id="img" />
              </template>
              <template v-if="deviceInfo != null&& file != ''">
                <img :src="fileImage" alt="이미지" id="img" />
                <a class="cancel" @click="deleteImage">
                  <img
                      src="@/assets/images/icon_svg/ic_delete_wh.svg"
                      alt="icon"
                  />
                </a>
              </template>

            </div>
          </div>
        </div>

        <div v-if="editMode" class="flexL">
          <p class="bold">{{ $t("device-data-device-regdate") }}</p>
          <p>{{ moment(registerDate).format("YYYY.MM.DD HH:mm:ss") }}</p>
        </div>

        <div class="buttonWrap">
          <button v-if="editMode" class="large left" @click="handleDelete">
            {{ $t("btn-delete") }}
          </button>
          <div>
            <button class="point large" @click="submit">{{ $t("btn-confirm") }}</button>
            <button class="large" @click="handleCancel">{{ $t("btn-cancel") }}</button>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="visible == 1" class="dim on">
        <!-- <div class="dim on"> -->
        <SelectTeam />
      </div>
      <div v-if="visible == 2" class="dim on">
        <!-- <div class="dim on"> -->
        <!-- <SearchAddress /> -->
      </div>
    </transition>
  </div>
</template>
<style>
</style>
<script>
import SelectTeam from "@/components/pop/SelectTeam";
// import SearchAddress from "@/components/pop/SearchAddress";
import {
  createDevice,
  updateDevice,
  fetchDeviceSerial,
  fetchDeviceDetail,
  fetchManufacturerList,
  fetchModelList,
  deleteDevice,
  getDeviceImgUrl,
  getDeviceImgDefault
} from "@/api/device";
import { fetchDepartmentList } from "@/api/department";
import moment from "moment";
import { baseUrl } from "@/config/env";
export default {
  components: {
    SelectTeam,
    // SearchAddress
  },
  name: "DroneRegister",
  data() {
    return {
      editMode: false,
      moment: moment,
      visible: false,
      visibleInput: false,
      modelNameInput: "",
      accountId: "",
      deviceId: "",
      serial: "",
      manufacturerList: [],
      manufacturer: "",
      manufacturerObj: "",
      manufacturerId: null,
      selectManufacturerId: null,
      manufacturerName: "",
      modelList: [],
      modelObj: {},
      modelId: null,
      selectModelId : null,
      modelName: "",
      approval: "",
      deviceName: "",
      ownerTitle: "",
      ownerName: "",
      ownerPhone: "",
      fileImage: "",
      deviceImageUrl: null,
      registerDate: "",
      message: "",
      img: "",
      file: "",
      duplicateCheck: false,
      deviceManufacturerId: "",
      baseUrl: baseUrl,
      defaultImage: true,
      departmentList: [],
      department: null,

      deviceInfo : null,
    };
  },
  async created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 5, sub: 0 });
    this.accountId = this.$route.params.accountId;
    if (this.$route.query.deviceId) {
      this.editMode = true;
      this.getDeviceDetail();
    }
    this.getManufacturerList();
    this.getDepartmentList();
  },
  watch : {
    ownerPhone(val){
      let check = /^[0-9]+$/;
      if (!check.test(val)) {
        this.ownerPhone = val.replace(/[^0-9]/g,'');
      }
    },
    serial(val){
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

      // 한글, 영문 체크
      if(reg.exec(val)!==null) this.serial = val.replace(/[^a-z0-9]/gi,'');
    },
  },
  methods: {
    getDeviceImgDefault(){
      return getDeviceImgDefault(this.selectManufacturerId, this.selectModelId);
    },
    handleImg() {
      if (this.defaultImage) {
        this.deleteImage();
      }
    },
    bindNumber(event){
      this.ownerPhone = event.target.value;
    },
    bindNumberAndEn(event){
      this.serial = event.target.value;
    },
    departmentCheck() {},
    deleteImage() {
      this.fileImage = "";
      this.file = "";
    },
    handleTab(id) {
      this.visible = id;
    },
    handleRefresh() {
      this.$router.go();
    },
    onSelectManufacture(manufacturerId) {
      this.selectManufacturerId = manufacturerId;
      let params = {
        deviceManufacturerId: this.selectManufacturerId,
      };
      fetchModelList(params).then((res) => {
        this.modelList = res.data.data;
      });
      if(this.selectManufacturerId == this.manufacturerId) {
        this.selectModelId = this.modelId;
      } else {
        this.selectModelId = null;
      }
    },
    onSelectModel(id){
      this.selectModelId = id;
    },
    getManufacturerList() {
      let params = {
        manufacturerType: "Drone",
      };
      fetchManufacturerList(params).then((res) => {
        this.manufacturerList = res.data.data;
      });
    },

    handleDevice() {
      if(this.serial == null || this.serial.trim() == ""){
        this.message = this.$t("alert-message-check-is-insert-serial-num");
        return;
      }
      fetchDeviceSerial(this.serial).then((res) => {
        if (res.data.result == 0) {
          this.message = this.$t("alert-message-check-serial-num-confirm");
          this.duplicateCheck = true;
        } else {
          this.message = this.$t("alert-message-check-double-serial-num");
        }
      });
    },
    handleCancel() {
      this.$router.go(-1);
    },
    handleDelete() {
      let deviceId = this.$route.query.deviceId;
      let result = confirm(this.$t("alert-message-delete-device-detail"));
      if (result == false) {
        return;
      } else {
        deleteDevice(deviceId).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-delete"));
            this.$router.go(-1);
          }
        });
      }
    },
    handleUpload() {},
    sendFile() {
      this.file = this.$refs.images.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e) => {
        this.fileImage = e.target.result;
      };
    },
    getDepartmentList() {
      var params = {
        statuses : "Used"
      }
      fetchDepartmentList(params).then((res) => {
        this.departmentList = res.data.data.content;
      });
    },
    getDeviceDetail() {
      let deviceId = this.$route.query.deviceId;
      fetchDeviceDetail(deviceId).then((res) => {
        if (res.data.result == 0) {
          this.deviceInfo = res.data.data;
          this.deviceId = res.data.data.deviceId;
          this.accountId = res.data.data.accountId;
          this.serial = res.data.data.serial;
          this.manufacturerId = res.data.data.manufacturerId;
          this.manufacturerName = res.data.data.manufacturerName;
          this.manufacturerObj = res.data.data.manufacturer;
          this.modelObj = res.data.data.model;
          this.modelId = res.data.data.modelId;
          this.modelName = res.data.data.modelName;
          this.approval = res.data.data.approval == null ? "" : res.data.data.approval;
          this.deviceName = res.data.data.deviceName;
          this.ownerTitle = res.data.data.ownerTitle;
          this.ownerName = res.data.data.ownerName;
          this.ownerPhone = res.data.data.ownerPhone;
          this.deviceImageUrl = getDeviceImgUrl(this.accountId, this.deviceId);
          this.registerDate = res.data.data.registerDate;
          this.department = res.data.data.ownerDepartmentId;
          this.defaultImage = res.data.data.defaultIsImage;
          this.onSelectManufacture(this.manufacturerId);
          this.onSelectModel(this.modelId);
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    submit() {

      if (this.serial == "") {
        return alert(this.$t("alert-message-check-device-serial-num"));
      } else if (this.editMode == false && this.duplicateCheck == false) {
        return alert(this.$t("alert-message-check-device-duplicate"));
      } else if (this.selectManufacturerId == null) {
        return alert(this.$t("alert-message-check-device-manufacture"));
      } else if (this.selectModelId == null) {
        return alert(this.$t("alert-message-check-device-model"));
      } else if (this.deviceName == "") {
        return alert(this.$t("alert-message-check-device-device-name"));
      } else if (!this.department) {
        return alert(this.$t("alert-message-check-dept-name"));
      } else if (!this.ownerName) {
        return alert(this.$t("alert-message-check-dept-manager"));
      } else if (!this.ownerPhone) {
        return alert(this.$t("alert-message-check-call"));
      }

      this.modelId = this.selectModelId;
      this.manufacturerId = this.selectManufacturerId;

      let formData = new FormData();
      formData.append("uploadImage", this.file);
      formData.append("manufacturerId", this.manufacturerId);
      formData.append("modelId", this.modelId);
      formData.append("deviceName", this.deviceName);
      formData.append("ownerDepartmentId", this.department);
      formData.append("ownerName", this.ownerName);
      formData.append("ownerPhone", this.ownerPhone);
      formData.append("approval", this.approval);
      formData.append("defaultIsImage", this.defaultImage);

      if (this.editMode) {
        let deviceId = this.$route.query.deviceId;
        updateDevice(deviceId, formData).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-data-save"));
            this.$router.push({ name: "deviceList" });
          } else {
            let message = res.data.message;
            alert(message);
          }
        }).catch(e => {
          console.error(e)
        });
      } else {
        formData.append("serial", this.serial);
        createDevice(formData).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-data-save"));
            this.$router.push({ name: "deviceList" });
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
  },
  handleRefresh() {
    this.$router.go();
  },
};
</script>